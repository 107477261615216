import * as Yup from "yup";
import { solaValidation, tekmovalciValidation, mentorValidation, pravilaValidation, organizatorValidation, imeEkipeValidation } from './validationSchema';

export default Yup.object({
    recaptcha: Yup.string().required(),
    ...solaValidation,
    ...mentorValidation,
    ...imeEkipeValidation,
    disciplinaDirkac: Yup.bool(),
    poddisciplinaDirkac:
        Yup.string()
            .when('disciplinaDirkac', {
                is: true,
                then: Yup.string().required("Prosimo, izberite poddisciplino.")
            }),
    ...tekmovalciValidation,
    ...organizatorValidation,
    roboti:
        Yup.array().of(
            Yup.object({
                imeRobota: Yup.string().required("Prosimo, vnesite ime robota."),
                mereRobota: Yup.string().required("Prosimo, vnesite mere robota."),
                masaRobota: Yup.string().matches(/^[0-9.,\s]+$/, 'Masa robota mora biti število.').required("Prosimo, vnesite maso robota."),
                //dodatno: Yup.string().required("Prosimo, vnesite dodatne informacije robota."),
            })
        ),
    samostojnoIzdelanRobot:
        Yup.boolean()
            .oneOf([true], "Potrdite, da je robot zgrajen samostojno ob pomoči mentorja."),
    ...pravilaValidation
});
