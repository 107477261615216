import React, { useState } from 'react';
import { Formik, FieldArray, Field, ErrorMessage } from 'formik';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import ObrazecService from '../../services/obrazec.service';
import CapitalizeService from '../../services/capitalize.service';
import { scrollToTopParent } from '../../services/iframe.service';

import { Input } from '../../components/InputFormControl';
import SelectFormControl from '../../components/SelectFormControl';

import Sola from '../../components/Sola';
import Tekmovalci from '../../components/Tekmovalci';
import Pogoji from '../../components/Pogoji';
import Predtekmovanje from '../../components/Predtekmovanje';
import Infos from '../../components/Infos';
import Captcha from '../../components/Captcha';

import poddiscipline from '../../data/poddiscipline.json';
import osnovneSole from '../../data/osnovne.json';
import validationSchemaSled from '../../schemas/validationSchemaSled';
import {
    NAJVEC_ROBOTOV,
    NAJVEC_TEKMOVALCEV,
    NAJMANJ_TEKMOVALCEV,
    prazenTekmovalec,
    initialValuesObject,
    prazenRobot,
    wordsToCapitalize
} from './roboSled.config';

library.add(faPlus, faTrashAlt, faQuestionCircle);

const sole = osnovneSole.sort((a, b) => (a.naziv > b.naziv) ? 1 : -1)

const scrollToTop = () => window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth'
});

export default ({ history }) => {
    //const year = new Date().getFullYear();
    document.title = 'ROBOsled '; //+ year;

    const [onlineError, setOnlineError] = useState(false);

    const isTouched = (touched, indeks, lastnostOtrok, lastnostStars = 'roboti') =>
        touched[lastnostStars] && touched[lastnostStars][indeks] ? touched[lastnostStars][indeks][lastnostOtrok] : false;

    const hasErrors = (errors, indeks, lastnostOtrok, lastnostStars = 'roboti') =>
        errors[lastnostStars] && errors[lastnostStars][indeks] ? errors[lastnostStars][indeks][lastnostOtrok] : false;

    const addRobotHandler = (arrayHelpers, stRobotov) => {
        if (stRobotov < NAJVEC_ROBOTOV) {
            arrayHelpers.push({
                ...prazenRobot
            });
        }
    }

    const removeRobotHandler = (event, arrayHelpers, indeks, stRobotov) => {
        event.preventDefault();
        if (stRobotov >= NAJVEC_ROBOTOV) {
            arrayHelpers.remove(indeks);
        } else {
            alert(`Napaka pri brisanju.`);
        }
    }

    const submitData = async (values, actions) => {
        scrollToTop();
        scrollToTopParent();
        try {

            /* */
            CapitalizeService(values, wordsToCapitalize);
            const mentorEmail = values.mentorEposta;
            const imeEkipe = values.imeEkipe;
            const response = await ObrazecService(values, process.env.REACT_APP_ROBOSLED_ENDPOINT, mentorEmail, imeEkipe);
            if (response.status === 201) {  // 201 - created
                history.push('/uspesno-poslano/sled', values);
            }
            /* */
        } catch (error) {
            console.warn(error);
            setOnlineError(true);
            /*actions.setErrors({
                httpError: JSON.stringify(error)
            });*/
        } finally {
            //actions.resetForm();
            actions.setSubmitting(false);
        }
    }

    const onButtonClick = e => {
        scrollToTop();
        scrollToTopParent();
    }

    return (
        <Formik
            onSubmit={submitData}
            initialValues={initialValuesObject}
            validationSchema={validationSchemaSled}
            render={({
                values,
                touched,
                setValues,
                setTouched,
                errors,
                isSubmitting,
                handleSubmit,
                isValid,
                submitCount,
                setFieldValue
            }) => (
                    <>
                        <div className="row">
                            <div className="col mt-5 mb-5">
                                <h2>Prijavnica za državno robotsko tekmovanje v kategoriji</h2>
                                <h1>ROBOsled {/*year*/}</h1>
                            </div>
                        </div>

                        <Infos isSubmitting={isSubmitting} submitCount={submitCount} isValid={isValid} onlineError={onlineError} />

                        <form onSubmit={handleSubmit} className="needs-validation" noValidate>
                            {/*JSON.stringify(touched)*/}

                            {/* ŠOLA */}
                            <Sola
                                sole={sole}
                                errors={errors}
                                touched={touched}
                                values={values}
                                setTouched={setTouched}
                                setValues={setValues}>
                            </Sola>

                            {/* MENTOR */}
                            <fieldset className="scheduler-border">
                                <legend className="scheduler-border">
                                    Mentor
                                 </legend>
                                <div className="form-group form-row mb-md-3 mb-0">
                                    <label className="col-md-2 pr-0 mr-0 col-form-label text-md-right text-sm-left" htmlFor="mentorIme">Ime</label>
                                    <div className="col-md-4">
                                        <Input data-capitalize errors={errors.mentorIme} touched={touched.mentorIme} id="mentorIme" title="Ime" maxLength={35} />
                                    </div>
                                    <label className="col-md-1 pr-0 mr-0 col-form-label text-md-right text-sm-left" htmlFor="mentorPriimek">Priimek</label>
                                    <div className="col-md-5">
                                        <Input data-capitalize errors={errors.mentorPriimek} touched={touched.mentorPriimek} id="mentorPriimek" title="Priimek" maxLength={35} />
                                    </div>
                                </div>
                                <div className="form-group form-row mb-md-2 mb-0">
                                    <label className="col-md-2 pr-0 mr-0 col-form-label text-md-right text-sm-left" htmlFor="mentorEposta">E-pošta</label>
                                    <div className="col-md-4">
                                        <Input errors={errors.mentorEposta} touched={touched.mentorEposta} id="mentorEposta" title="E-pošta" />
                                    </div>
                                    <label className="col-md-1 pr-0 mr-0 col-form-label text-md-right text-sm-left" htmlFor="mentorTelefon">Telefon</label>
                                    <div className="col-md-5">
                                        <Input errors={errors.mentorTelefon} touched={touched.mentorTelefon} id="mentorTelefon" title="Telefon" />
                                    </div>
                                </div>
                            </fieldset>

                            {/* EKIPA */}
                            <fieldset className="scheduler-border">
                                <legend className="scheduler-border">Ekipa</legend>

                                <div className="form-group form-row mt-3">
                                    <label className="col-md-2 pr-0 mr-0 col-form-label text-md-right text-sm-left" htmlFor="imeEkipe">Ime ekipe
                                    <sup title="Izbrano ime ekipe naj bo primerno za natis na uradna priznanja.">
                                            <FontAwesomeIcon
                                                className="fas"
                                                icon="question-circle"
                                                data-toggle="tooltip"
                                            />
                                        </sup>
                                    </label>
                                    <div className="col-md-10">

                                        <Input
                                            title="Ime ekipe (do 20 znakov)"
                                            touched={touched.imeEkipe}
                                            errors={errors.imeEkipe}
                                            id="imeEkipe"
                                            value={values.imeEkipe}
                                            onKeyUp={e => e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)}
                                        />
                                        <small className="form-text text-muted d-sm-block d-md-none">Izbrano ime ekipe naj bo primerno za natis na uradna priznanja.</small>
                                    </div>
                                </div>


                                <div className="form-group form-row mt-0 mb-0">
                                    <div className="col-md-2"></div>
                                    <div className="col-md-2 col-form-label ml-3 ml-md-0">
                                        <Field type="checkbox" className="form-check-input" checked={values.disciplinaDirkac} id="disciplinaDirkac" name="disciplinaDirkac" disabled="disabled" />
                                        <label className="form-check-label" htmlFor="disciplinaDirkac">Disciplina DIRKAČ</label>
                                    </div>


                                    {values.disciplinaDirkac === true && <>
                                        <label className="col-md-3 pr-0 mr-0 col-form-label text-md-right text-sm-left" htmlFor="poddisciplinaDirkac">Poddisciplina
                                        <sup
                                                title="Tekmovanje ROBOsled DIRKAČ se izvede v treh ločenih poddisciplinah glede na zgradbo in krmiljenje robota."
                                            >
                                                <FontAwesomeIcon
                                                    className="fas"
                                                    icon="question-circle"
                                                    data-toggle="tooltip"
                                                />
                                            </sup>
                                        </label>

                                        <div className="col-md-5">
                                            <SelectFormControl errors={errors.poddisciplinaDirkac} touched={touched.poddisciplinaDirkac} name={'poddisciplinaDirkac'} items={poddiscipline} />
                                            <small className="form-text text-muted d-sm-block d-md-none">Tekmovanje ROBOsled DIRKAČ se izvede v treh ločenih poddisciplinah glede na zgradbo in krmiljenje robota.</small>
                                        </div>
                                    </>
                                    }

                                </div>
                                <div className="form-group form-row mt-0 mb-0">
                                    <div className="col-md-2"></div>
                                    <div className="col-md-10 col-form-label ml-3 ml-md-0">
                                        <Field type="checkbox" className="form-check-input" checked={values.disciplinaPoznavalec} id="disciplinaPoznavalec" name="disciplinaPoznavalec" />
                                        <label className="form-check-label" htmlFor="disciplinaPoznavalec">Disciplina POZNAVALEC</label>
                                    </div>
                                </div>

                                {/* 
                                <div className="form-group form-row mt-0 mb-0">
                                    <div className="col-md-2"></div>
                                    <div className="col-md-10 col-form-label ml-3 ml-md-0">
                                        <Field type="checkbox" className="form-check-input" checked={values.disciplinaInovator} id="disciplinaInovator" name="disciplinaInovator" />
                                        <label className="form-check-label" htmlFor="disciplinaInovator">Disciplina INOVATOR</label>
                                    </div>
                                </div>
                                */}
                                <Tekmovalci
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    prazenTekmovalec={prazenTekmovalec}
                                    NAJMANJ_TEKMOVALCEV={NAJMANJ_TEKMOVALCEV}
                                    NAJVEC_TEKMOVALCEV={NAJVEC_TEKMOVALCEV}
                                    info={'Pravila za tekmovanje ROBOsled v Sloveniji omejujejo sestavo ekipe na najmanj 1 in največ 2 tekmovalceva.'}
                                ></Tekmovalci>
                            </fieldset>

                            {/* PREDTEKMOVANJE */}
                            <Predtekmovanje values={values} errors={errors} touched={touched} setValues={setValues}></Predtekmovanje>

                            {/* ROBOT */}
                            <fieldset className="scheduler-border">
                                <legend className="scheduler-border">
                                    Robot
                                 </legend>
                                <FieldArray
                                    name="roboti"
                                    render={arrayHelpers => (
                                        <>
                                            {values.roboti.map((robot, index) => (
                                                <span key={index}>
                                                    <div className="row">
                                                        <h6 className="col">Robot {index + 1}</h6>
                                                    </div>
                                                    <hr className="mt-0 mb-md-3 mb-0" />
                                                    <div className="form-group form-row mb-md-3 mb-0">
                                                        {index >= NAJVEC_ROBOTOV - 1 && (
                                                            <div className="col-md-1 col-form-label">
                                                                <button type="button" className="btn btn-outline-dark btn-sm mt-1" data-toggle="tooltip" title="Odstrani robota" onClick={(e) => removeRobotHandler(e, arrayHelpers, index, values.roboti.length)}>
                                                                    <FontAwesomeIcon icon={['far', "trash-alt"]} className="far" />
                                                                </button>
                                                            </div>
                                                        )}
                                                        <label className={`col-md-${index >= NAJVEC_ROBOTOV - 1 ? 2 : 3} pr-0 mr-0 col-form-label text-md-right text-sm-left`} htmlFor="imeRobota">Ime robota</label>
                                                        <div className="col-md-9">
                                                            <Input
                                                                title="Ime robota"
                                                                touched={isTouched(touched, index, 'imeRobota')}
                                                                errors={hasErrors(errors, index, 'imeRobota')}
                                                                id={`roboti.${index}.imeRobota`}
                                                                value={robot.imeRobota}
                                                                onKeyUp={e => e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group form-row mb-md-3 mb-0">
                                                        <label className="col-md-3 pr-0 mr-0 col-form-label text-md-right text-sm-left" htmlFor="mereRobota">Zun. mere robota (dxšxv v cm)</label>
                                                        <div className="col-md-4">
                                                            <Input
                                                                title="Zun. mere robota (dxšxv v cm)"
                                                                touched={isTouched(touched, index, 'mereRobota')}
                                                                errors={hasErrors(errors, index, 'mereRobota')}
                                                                id={`roboti.${index}.mereRobota`}
                                                                value={robot.mereRobota}
                                                            />
                                                        </div>
                                                        <label className="col-md-2 pr-0 mr-0 col-form-label text-md-right text-sm-left" htmlFor="masaRobota">Masa robota (g)</label>
                                                        <div className="col-md-3">
                                                            <Input
                                                                title="Masa robota (g)"
                                                                touched={isTouched(touched, index, 'masaRobota')}
                                                                errors={hasErrors(errors, index, 'masaRobota')}
                                                                id={`roboti.${index}.masaRobota`}
                                                                value={robot.masaRobota}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group form-row mb-md-3 mb-0">
                                                        <label className="col-md-3 pr-0 mr-0 col-form-label text-md-right text-sm-left" htmlFor="dodatno">Dodatno
                                                            <sup title="Naziv uporabljenega kompleta oz. tip mikrokrmilnika.">
                                                                <FontAwesomeIcon
                                                                    className="fas"
                                                                    icon="question-circle"
                                                                    data-toggle="tooltip"
                                                                />
                                                            </sup>
                                                        </label>
                                                        <div className="col-md-9">
                                                            <Input
                                                                title="Dodatno"
                                                                //touched={isTouched(touched, index, 'dodatno')}
                                                                //errors={hasErrors(errors, index, 'dodatno')}
                                                                id={`roboti.${index}.dodatno`}
                                                                value={robot.dodatno}
                                                            />
                                                            <small className="form-text text-muted d-sm-block d-md-none">Naziv uporabljenega kompleta oz. tip mikrokrmilnika.</small>
                                                        </div>
                                                    </div>

                                                </span>
                                            ))}

                                            {values.roboti.length < NAJVEC_ROBOTOV && <>

                                                <div className="form-group form-row mt-3 mb-md-2 mb-0">
                                                    <div className="col-3 text-right">
                                                        <button
                                                            type="button"
                                                            className="btn btn-outline-dark btn-sm"
                                                            onClick={() => addRobotHandler(arrayHelpers, values.roboti.length)}>
                                                            <FontAwesomeIcon icon="plus" />
                                                        </button>
                                                    </div>
                                                    <label className="col-9 col-form-label text-left">Dodaj robota
                                                            <sup
                                                            title="Če bo ekipa tekmovala z dvema robotoma (oba morata biti uvrščena v isto poddisciplino), navedite oba robota."
                                                        >
                                                            <FontAwesomeIcon
                                                                className="fas"
                                                                icon="question-circle"
                                                                data-toggle="tooltip"
                                                            />
                                                        </sup>
                                                    </label>
                                                    <small className="form-text text-muted d-sm-block d-md-none">Če bo ekipa tekmovala z dvema robotoma (oba morata biti uvrščena v isto poddisciplino), navedite oba robota.</small>

                                                </div>
                                            </>
                                            }
                                        </>)}

                                />

                            </fieldset>

                            {/* POGOJI */}
                            <Pogoji obrazecPot="/vzorec.pdf">
                                <>
                                    <div className="form-group form-row">
                                        <div className="col-md-1"></div>
                                        <div className="col-md-11 col-form-label ml-3 ml-md-0">
                                            <Field className="form-check-input" type="checkbox" id="samostojnoIzdelanRobot" name="samostojnoIzdelanRobot" required />
                                            <label className="form-check-label" htmlFor="samostojnoIzdelanRobot">Mentor in tekmovalci izjavljamo, da je robot, s katerim
                                              ekipa tekmuje na državnem tekmovanju ROBOsled, zgrajen samostojno ob pomoči mentorja, pri čemer je bil vsak član
                                              ekipe pomembno udeležen. Mentor ni priskrbel svoji ekipi najboljše vnaprej dane rešitve, ampak je ekipi pomagal pri
                razvoju in gradnji robota skozi spoznavanje in raziskovanje. <br />
                                            </label>
                                            <small>V kolikor tekmovalna komisija ugotovi kršitev, sledi takojšnja diskvalifikacija ekipe.</small>
                                            <ErrorMessage component="div" name="samostojnoIzdelanRobot" className="text-danger small" />
                                        </div>
                                    </div>
                                    <hr className="mb-3 mt-1" />
                                </>

                            </Pogoji>

                            <Captcha
                                setFieldValue={setFieldValue}
                                name={'recaptcha'}
                                errors={errors.recaptcha}
                                submitCount={submitCount}
                            />

                            <div className="row mb-5">
                                <div className="col-12 text-right">
                                    <button className="btn btn-primary" type="submit" onClick={e => onButtonClick(e, errors)} >Pošlji prijavo</button>
                                </div>
                            </div>
                        </form>
                    </>
                )}
        />)
}