import React from 'react';
import { Field } from 'formik';

export default ({ errors, touched, name, items }) => <>
    <Field
        component="select"
        className={`custom-select 
                    ${errors && touched ? "is-invalid" : ""} 
                    ${!errors && touched ? "is-valid" : ""}`}
        name={name}>
        {items.map((item, index) => <option key={index} value={item.value}>{item.title}</option>)}
    </Field>
    <div className="invalid-feedback">{errors}</div>
</>;