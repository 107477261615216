import React from 'react';
import discipline from '../../data/discipline.json';
import poddiscipline from '../../data/poddiscipline.json';
import nivo from '../../data/nivo.json';
import { setIFrameHeight } from "../../services/iframe.service";
/*
nazivSole: '',
    naslovSole: '',
    postnaStevilkaSole: '',
    postaSole: '',
    mentorji: [{
        ...prazenMentor
    }],
    disciplinaIzberi: '',
    nivoIzberi: '',
    imeEkipe: 'testna ekipa',
    mesanaEkipa: false,
    tekmovalci: [{
        ...prazenTekmovalec
    }, {
        ...prazenTekmovalec
    }],
    izberiOrganizatorja: '',
    regijaOrganizatorja: '',
    dosezenoMesto: '4',
    dosezenoStTock: '4',
    sprejemPravil: true,
    pravilnaPrijava: true,
    soglasjeStarsi: true

     mentorIme: 'mentor 1',
    mentorPriimek: 'mentor 2',
    mentorEposta: 'posta@mentor.si',
    mentorTelefon: '04312'
};

export const prazenTekmovalec = {
    tekmovalecIme: 'tekmovalec 1',
    tekmovalecPriimek: 'tekmovalec 2',
    tekmovalecRazred: '4'


*/

const Sola = ({ nazivSole, naslovSole, postnaStevilkaSole, postaSole }) => <tr>
    <th scope="row">Šola</th>
    <td>{nazivSole}, {naslovSole}, {postnaStevilkaSole} {postaSole}</td>
</tr>

const Mentor = (mentor) => <>{mentor.mentorIme} {mentor.mentorPriimek}, e-pošta: {mentor.mentorEposta}, tel.: {mentor.mentorTelefon}</>

const Tekmovalci = ({ tekmovalci }) => <ul style={{ margin: 0 }}>
    {tekmovalci.map((tekmovalec, index) =>
        <li key={index}>{tekmovalec.tekmovalecIme} {tekmovalec.tekmovalecPriimek}, razred: {tekmovalec.tekmovalecRazred}</li>
    )}
</ul>

function DisplayRoboCup(obrazec) {
    const { nazivSole,
        naslovSole,
        postnaStevilkaSole,
        postaSole,
        mentorji,
        disciplinaIzberi,
        nivoIzberi,
        imeEkipe,
        mesanaEkipa,
        tekmovalci,
        regijaOrganizatorja,
        dosezenoMesto,
        dosezenoStTock } = obrazec;

    return (<table className="table">
        <tbody>
            <Sola nazivSole={nazivSole} naslovSole={naslovSole} postnaStevilkaSole={postnaStevilkaSole} postaSole={postaSole} />
            <tr>
                <th scope="row">Mentorji</th>
                <td>
                    {mentorji.length === 1 && <Mentor {...mentorji[0]} />}

                    {mentorji.length > 1 && <ul>
                        {mentorji.map((mentor, index) =>
                            <li key={index}><Mentor {...mentor} /></li>
                        )}
                    </ul>}
                </td>
            </tr>
            <tr>
                <th scope="row">Tekmovanje</th>
                <td>{discipline.find(d => d.value === disciplinaIzberi).title}, {nivo.find(n => n.value === nivoIzberi).title}</td>
            </tr>
            <tr>
                <th scope="row">Ekipa</th>
                <td>
                    {imeEkipe}{mesanaEkipa && <>&nbsp;(mešana ekipa)</>}:
                    <Tekmovalci tekmovalci={tekmovalci} />
                </td>
            </tr>
            <tr>
                <th scope="row">Regijsko predtekmovanje</th>
                <td>{regijaOrganizatorja}, doseženo mesto: {dosezenoMesto} (doseženo št. točk: {dosezenoStTock})</td>
            </tr>
        </tbody>
    </table>)
}

function DisplayRoboSled(obrazec) {
    const { nazivSole,
        naslovSole,
        postnaStevilkaSole,
        postaSole,
        mentorIme,
        mentorPriimek,
        mentorEposta,
        mentorTelefon,
        disciplinaDirkac,
        disciplinaInovator,
        disciplinaPoznavalec,
        poddisciplinaDirkac,
        imeEkipe,
        tekmovalci,
        regijaOrganizatorja,
        dosezenoMesto,
        roboti
    } = obrazec;

    const mentor = {
        mentorIme,
        mentorPriimek,
        mentorEposta,
        mentorTelefon
    }

    console.log(obrazec);
    return (<table className="table">
        <tbody>
            <Sola nazivSole={nazivSole} naslovSole={naslovSole} postnaStevilkaSole={postnaStevilkaSole} postaSole={postaSole} />
            <tr>
                <th>Mentor</th>
                <td>
                    <Mentor {...mentor} />
                </td>
            </tr>
            <tr>
                <th scope="row">Tekmovanje</th>
                <td>
                    <ul>
                        {disciplinaDirkac && <li>Disciplina DIRKAČ, poddisciplina: {poddiscipline.find(pd => pd.value === poddisciplinaDirkac).title}</li>}
                        {disciplinaPoznavalec && <li>Disciplina POZNAVALEC</li>}
                        {disciplinaInovator && <li>Disciplina INOVATOR</li>}
                    </ul>

                </td>
            </tr>
            <tr>
                <th scope="row">Ekipa</th>
                <td>
                    {imeEkipe}
                    <Tekmovalci tekmovalci={tekmovalci} />
                </td>
            </tr>
            <tr>
                <th scope="row">Regijsko predtekmovanje</th>
                <td>{regijaOrganizatorja}, doseženo mesto: {dosezenoMesto}</td>
            </tr>
            <tr>
                <th scope="row">Roboti</th>
                <td>
                    <ul style={{ margin: 0 }}>
                        {roboti.map((robot, index) =>
                            <li key={index}>{robot.imeRobota}, Zun. mere robota (dxšxv v cm): {robot.mereRobota} ,masa robota (g): {robot.masaRobota} {robot.dodatno && <>, dodatno: {robot.dodatno}</>}</li>
                        )}
                    </ul>
                </td>
            </tr>
        </tbody>
    </table>)

}

export default props => {
    let title = '';
    let report = null;
    const data = props.location.state;

    switch (props.match.params.type) {
        case 'sled':
            title = 'ROBOsled';
            report = <DisplayRoboSled {...data} />
            break;
        case 'junior':
            title = 'RoboCupJunior Slovenija';
            report = <DisplayRoboCup {...data} />
            break;
        default:
            props.history.push('/');
            break;
    }

    React.useEffect(() => {
        setIFrameHeight();
    }, []);

    return (<>
        <div className="row">
            <div className="col mt-5 mb-5">
                <h2>Prijavnica za državno robotsko tekmovanje v kategoriji</h2>
                <h1>{title}</h1>
            </div>
        </div>
        <div className="row mt-0 mb-md-4 mb-2">
            <div className="col-9 rotate_right">
                <img src={process.env.PUBLIC_URL + '/images/robot.png'} className="float-right" alt="Robotek" />
            </div>
        </div>
        <div className="row">
            <div className="col-10">
                <div className="alert alert-success" role="alert">
                    <h4 className="mb-3">Vaša prijava ekipe <strong>{data.imeEkipe}</strong> je bila uspešno zabeležena.</h4>
                    <p className="mb-0">Kmalu boste prejeli potrditveno e-poštno sporočilo. </p>

                </div>
            </div>
        </div>

        <div className="row mt-5 mb-5">
            <div className="col-10">
                <h4 className="mb-3">Podatki o prijavi</h4>
            </div>
            <div className="col-10">
                {report}
            </div>
        </div>

        <div className="row mt-5 mb-5">
            <div className="col-10 mb-5">
                Datum: <span style={{ width: '12em', display: 'inline-block', borderBottom: '1px solid black' }}></span>
            </div>
            <div className="col-10">
                Podpis mentorja: <span style={{ width: '12em', display: 'inline-block', borderBottom: '1px solid black' }}></span>
            </div>
        </div>

        <div className="row mt-5 mb-5">
            <div className="col-10">
                <strong>Prijavo obvezno natisnite!</strong>
                <p>
                    S podpisom mentor potrjuje pravilnost podatkov prijave.
                    <br />
                    Registracija ekipe na dan tekmovanja bo mogoča samo ob oddaji podpisane prijave.
                </p>
            </div>
        </div>

        <div className="row mt-5 mb-5">
            <div className="col-10">
                <button className="btn btn-primary" onClick={e => window.print()}>Natisni prijavo</button>
            </div>
        </div>
    </>)
};