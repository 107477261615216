import React from 'react';

import ReCAPTCHA from "react-google-recaptcha";

export default ({ name, setFieldValue, errors, submitCount }) => {
    const siteKey = process.env.REACT_APP_RECAPTCHA_KEY;

    const handleResponse = async (response) => {
        try {
            const result = await response;
            setFieldValue(name, response);
        } catch (error) {
            throw new Error(error);
        }
    }

    return <>
        <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-4">
                <ReCAPTCHA
                    sitekey={siteKey}
                    render="explicit"
                    onChange={response => handleResponse(response)}
                />
                {errors && submitCount > 0 && <p className="text-danger">Nisem robot</p>}
            </div>
            <div className="col-md-4"></div>
        </div>
    </>;
};
