import * as Yup from "yup";

export const solaValidation = {
    nazivSole: Yup.string()
        .required("Polje se izpolni samodejno, ko se izbere naziv šole v prvem vnosnem polju."),
    naslovSole: Yup.string()
        .required("Polje se izpolni samodejno, ko se izbere naziv šole v prvem vnosnem polju."),
    postaSole: Yup.string().required("Polje se izpolni samodejno, ko se izbere naziv šole v prvem vnosnem polju."),
    postnaStevilkaSole: Yup.string().required("Polje se izpolni samodejno, ko se izbere naziv šole v prvem vnosnem polju."),
};

export const tekmovalciValidation = {
    tekmovalci: Yup.array().of(
        Yup.object({
            tekmovalecIme: Yup.string().required("Prosimo, vnesite ime tekmovalca."),
            tekmovalecPriimek: Yup.string().required("Prosimo, vnesite priimek tekmovalca."),
            //tekmovalecRazred: Yup.string().matches(/^[1-9]{1}$/, 'Razred tekmovalca mora biti veljavno število.').required("Prosimo, vnesite razred tekmovalca.")
            tekmovalecRazred: Yup.string().required("Prosimo, vnesite razred tekmovalca.")
        })
    )
};

export const mentorValidation = {
    mentorIme:
        Yup.string()
            //.matches(/^[a-zA-ZŠČŽščžĐđĆć\s]*$/, 'Ime mentorja lahko vsebuje samo črke in presledke.')
            .required("Prosimo, vnesite ime mentorja."),
    mentorPriimek:
        Yup.string()
            //.matches(/^[a-zA-ZŠČŽščžĐđĆć\s]*$/, 'Priimek mentorja lahko vsebuje samo črke in presledke.')
            .required("Prosimo, vnesite priimek mentorja."),
    mentorEposta:
        Yup.string()
            .email('E-pošta ni pravilno oblikovana.')
            .required("Prosimo, vnesite e-pošto mentorja."),
    mentorTelefon:
        Yup.string()
            .matches(/^[0-9*#+-\s]+$/, "Telefonska številka ni pravilno oblikovana.")
            .required("Prosimo, vnesite telefonsko številko mentorja."),
};

export const pravilaValidation = {
    sprejemPravil:
        Yup.boolean()
            .oneOf([true], "Pravila je potrebno sprejeti."),
    pravilnaPrijava:
        Yup.boolean()
            .oneOf([true], "Potrdite, da je prijava v celoti pravilna."),
    soglasjeStarsi:
        Yup.boolean()
            .oneOf([true], "Potrdite, da ste prejeli soglasja staršev.")
};

export const organizatorValidation = {
    izberiOrganizatorja:
        Yup.string()
            .required("Prosimo, izberite organizatorja predtekmovanja."),
    regijaOrganizatorja:
        Yup.string()
            .required("Za izpis regije izberite organizatorja predtekmovanja."),
    dosezenoMesto:
        Yup.number()
            .typeError('Doseženo mesto mora biti število.')
            .required('Prosimo, vpišite doseženo mesto na predtekmovanju.'),
};

export const imeEkipeValidation = {
    imeEkipe:
        Yup.string()
            .max(20, "Ime ekipe je omejeno na 20 znakov.")
            .required("Prosimo, vnesite ime ekipe."),
}

/*nazivSole:
Yup.string()
    .min(3, "Naziv šole mora vsebovati vsaj 3 črke.")
    .required("Prosimo, vpišite naziv šole."),
naslovSole:
Yup.string()
    .min(3, "Naslov šole mora vsebovati vsaj 3 črke.")
    .required("Prosimo, vpišite naslov šole."),
postaSole:
Yup.string().matches(/^[a-zA-ZŠČŽščžáÁ-\s]*$/, 'Pošta šole lahko vsebuje samo črke in presledke.')
    .min(2, "Pošta mora vsebovati vsaj 2 črki.")
    .required("Prosimo, vpišite pošto šole."),
postnaStevilkaSole:
Yup.string().matches(/^[1-9][0-9]{3}$/, 'Poštna številka mora biti štirimestno število.')
    .required("Prosimo, vpišite poštno številko."),*/
