export const NAJVEC_MENTORJEV = 2;
export const NAJMANJ_MENTORJEV = 1;
export const NAJVEC_TEKMOVALCEV = 5;
export const NAJMANJ_TEKMOVALCEV = 2;

export const prazenMentor = {
    mentorIme: '',
    mentorPriimek: '',
    mentorEposta: '',
    mentorTelefon: ''
};

export const prazenTekmovalec = {
    tekmovalecIme: '',
    tekmovalecPriimek: '',
    tekmovalecRazred: ''
};

export const initialValuesObject = {
//    recaptcha: '',
    nazivSole: '',
    naslovSole: '',
    postnaStevilkaSole: '',
    postaSole: '',
    mentorji: [{
        ...prazenMentor
    }],
    disciplinaIzberi: '',
    nivoIzberi: '',
    imeEkipe: '',
    mesanaEkipa: false,
    tekmovalci: [{
        ...prazenTekmovalec
    }, {
        ...prazenTekmovalec
    }],
    izberiOrganizatorja: '',
    regijaOrganizatorja: '',
    dosezenoMesto: '',
    dosezenoStTock: '',
    sprejemPravil: false,
    pravilnaPrijava: false,
    soglasjeStarsi: false
};

export const wordsToCapitalize = [
    {
        id: 'mentorji',
        plain: false,
        capitalizeAll: true,
        values: ['mentorIme', 'mentorPriimek']
    }, {
        id: 'imeEkipe',
        plain: true,
        values: null,
        capitalizeAll: false
    }, {
        id: 'tekmovalci',
        plain: false,
        capitalizeAll: true,
        values: ['tekmovalecIme', 'tekmovalecPriimek']
    }];