import React, { Component } from 'react';
import RoboCupJunior from "./pages/RoboCupJunior/RoboCupJunior";
import RoboSled from "./pages/RoboSled/RoboSled";
import Home from "./pages/Home/Home";
import Potrditev from "./pages/Potrditev/Potrditev";
import { setIFrameHeight } from "./services/iframe.service";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

class App extends Component {

  constructor() {
    super();
    let robosledEnable = (process.env.REACT_APP_ROBOSLED_ENABLE === "true") ? true : false;
    let robocupJuniorEnable = (process.env.REACT_APP_ROBOCUP_JUNIOR_ENABLE === "true") ? true : false;
  
    this.state = {
      isRoboSled: robosledEnable,
      isRoboCupJunior: robocupJuniorEnable
    }
  }

  componentDidMount() {
    setIFrameHeight();
  }
  render() {

    return (
      <Router>
        <main>
          <div className="container">
            <Switch>
              {/* 
              <Route exact path="/" component={() => <Home isRoboCupJunior={this.state.isRoboCupJunior} isRoboSled={this.state.isRoboSled} />} />
              */}
              <Route exact path="/" component={this.state.isRoboCupJunior ? RoboCupJunior : () => <div>Not Allowed...</div>} />
              <Route path="/robocup-junior" component={this.state.isRoboCupJunior ? RoboCupJunior : () => <div>Not Allowed...</div>} />
              <Route path="/robosled" component={this.state.isRoboSled ? RoboSled : () => <div>Not Allowed...</div>} />
              <Route path="/uspesno-poslano/:type" component={Potrditev} />
              <Redirect to="/" />
            </Switch>
          </div>
        </main>
      </Router>
    );
  }
}

export default App;
