import axios from 'axios';
const url = process.env.REACT_APP_URL_BASE;
const API_KEY = "3cbd4dbd-3217-4389-94a0-b9ae0627ed56";

export default async (values, endPoint, mentorEmail, imeEkipe) => {
    try {
        const headers = {
            'API-Key': API_KEY,
            'mentor': mentorEmail,
            'imeEkipe': encodeURIComponent(imeEkipe)
        };
        //console.log(headers);
        return await axios.post(url + '/' + endPoint, values, {
            headers
        });
    } catch (error) {
        throw new Error(error);
    }
}