
import React from 'react';
import { Field } from 'formik';
import { Input } from './InputFormControl';
import organizatorji from '../data/organizatorji.json';

export default ({ values, errors, touched, setValues, children }) => {

    const handleOrganizatorChange = (e, values, setValues) => {
        const nazivSrednjeSole = e.target.value;
        if (nazivSrednjeSole) {
            const organizator = organizatorji.find(srednjaSola => srednjaSola.title.trim() === nazivSrednjeSole.trim());
            if (organizator) {
                setValues({
                    ...values,
                    regijaOrganizatorja: organizator.regija
                });
            }
        } else {
            setValues({
                ...values,
                regijaOrganizatorja: ''
            });
        }
    }

    return (
        <fieldset className="scheduler-border">
            <legend className="scheduler-border">
                Regijsko predtekmovanje
            </legend>
            <div className="form-group form-row mb-md-3 mb-0">
                <label className="col-md-4 pr-0 mr-0 col-form-label text-md-right text-sm-left" htmlFor="izberiOrganizatorja">
                    Izberite organizatorja predtekmovanja
                </label>
                <div className="col-md-8">

                    <Field
                        component="select"
                        className={`custom-select 
                ${errors.izberiOrganizatorja && touched.izberiOrganizatorja ? "is-invalid" : ""} 
                ${!errors.izberiOrganizatorja && touched.izberiOrganizatorja ? "is-valid" : ""}`
                        }
                        id="izberiOrganizatorja"
                        name="izberiOrganizatorja"
                        required
                        onClick={e => handleOrganizatorChange(e, values, setValues)}
                    >
                        <option value="">Izberite organizatorja predtekmovanja</option>
                        {organizatorji.filter(organizator => !organizator.disabled).map(organizator => (
                            // disabled={organizator.disabled ? true : false}
                            <option key={organizator.value} value={organizator.title}>{organizator.title}</option>
                        ))}
                    </Field>
                    <div className="invalid-feedback">{errors.izberiOrganizatorja}</div>

                </div>
            </div>
            <div className="form-group form-row mb-md-3 mb-0">
                <label className="col-md-4 pr-0 mr-0 col-form-label text-md-right text-sm-left" htmlFor="regijaOrganizatorja">Regija</label>
                <div className="col-md-8">
                    <Field type="text" className={`form-control ${!errors.regijaOrganizatorja && touched.izberiOrganizatorja ? "is-valid" : ""}`}
                        id="regijaOrganizatorja"
                        name="regijaOrganizatorja"
                        placeholder="Regija se določi samodejno"
                        readOnly
                        required
                    />

                </div>
            </div>
            <div className="form-group form-row mb-md-2 mb-0">
                <label className="col-md-4 pr-0 mr-0 col-form-label text-md-right text-sm-left" htmlFor="dosezenoMesto">Doseženo mesto</label>
                <div className="col-md-4">

                    {/* Doseženo mesto 1 znak */}
                    <Input id="dosezenoMesto" title="Doseženo mesto" errors={errors.dosezenoMesto} touched={touched.dosezenoMesto} maxLength={1} required />
                </div>
            </div>
            {children}
        </fieldset>
    )
}