import React from 'react';
import { Field } from 'formik';

export const Input = ({ errors, touched, id, title, readOnly, ...other }) => <>
    <Field
        type="text"
        className={`form-control 
        ${errors && touched ? "is-invalid" : ""} 
        ${!errors && touched ? "is-valid" : ""}`}
        id={id}
        name={id}
        placeholder={title}
        readOnly={readOnly ? true : false}
        {...other}
        required
    />
    <div className="invalid-feedback">{errors}</div>
</>

export default ({ className, id, title, colSize, ...props }) =>
    <>
        <label className={className ? className : 'col-md-2 pr-0 mr-0 col-form-label text-md-right text-sm-left'} htmlFor={id}>
            {title}
        </label>
        <div className={colSize}>
            <Input {...{ id, title, ...props }} />
        </div>
    </>