export const NAJVEC_TEKMOVALCEV = 2;
export const NAJMANJ_TEKMOVALCEV = 1;
export const NAJVEC_ROBOTOV = 2;
export const NAJMANJ_ROBOTOV = 1;

export const prazenRobot = {
    imeRobota: '',
    mereRobota: '',
    masaRobota: '',
    dodatno: ''
}

export const prazenTekmovalec = {
    tekmovalecIme: '',
    tekmovalecPriimek: '',
    tekmovalecRazred: ''
};

export const initialValuesObject = {
//    recaptcha: '',
    nazivSole: '',
    naslovSole: '',
    postnaStevilkaSole: '',
    postaSole: '',
    mentorIme: '',
    mentorPriimek: '',
    mentorEposta: '',
    mentorTelefon: '',
    imeEkipe: '',
    disciplinaDirkac: true,
    disciplinaPoznavalec: false,
    disciplinaInovator: false,
    poddisciplinaDirkac: '',
    tekmovalci: [{
        ...prazenTekmovalec
    }],
    izberiOrganizatorja: '',
    regijaOrganizatorja: '',
    dosezenoMesto: '',
    roboti: [{
        ...prazenRobot
    }],
    samostojnoIzdelanRobot: false,
    sprejemPravil: false,
    pravilnaPrijava: false,
    soglasjeStarsi: false
};

export const wordsToCapitalize = [{
    id: 'mentorIme',
    plain: true,
    values: null,
    capitalizeAll: true
}, {
    id: 'mentorPriimek',
    plain: true,
    values: null,
    capitalizeAll: true
}, {
    id: 'imeEkipe',
    plain: true,
    values: null,
    capitalizeAll: false
}, {
    id: 'tekmovalci',
    plain: false,
    capitalizeAll: true,
    values: ['tekmovalecIme', 'tekmovalecPriimek']
}, {
    id: 'roboti',
    plain: false,
    capitalizeAll: false,
    values: ['imeRobota']
}];