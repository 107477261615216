import React from 'react';

export default ({ isValid, submitCount, isSubmitting, onlineError }) => (
    <>
        {!isValid && submitCount > 0 &&
            <div className="alert alert-warning" role="alert">
                Prosimo, preverite ali so vsa zahtevana polja pravilno izpolnjena.
            </div>}

        {onlineError &&
            <div className="alert alert-danger" role="alert">
                Napaka na strežniku. Prosimo poskusite kasneje. <br />
            </div>}

        {isSubmitting && <div className="alert alert-info" role="alert">Pošiljam podatke...</div>}
    </>);