import React from 'react';
import FormGroup from './FormGroup';
import InputFormControl from './InputFormControl';

export default ({ sole, errors, touched, values, setTouched, setValues }) => {


    const updateValues = (e, currentValues, setValues, currentTouched, setTouched) => {

        const izbranaSola = e.target.value;
        const sola = sole.find(sola => sola.naziv.trim() === izbranaSola.trim())
        if (sola) {
            // If users selects an item from dropdown
            const { naziv, naslov, postna, posta } = sola;
            setValues({
                ...currentValues,
                nazivSole: naziv,
                naslovSole: naslov,
                postnaStevilkaSole: postna,
                postaSole: posta
            });

        } else {
            // If users selects empty value from dropdown
            setValues({
                ...currentValues,
                nazivSole: '',
                naslovSole: '',
                postnaStevilkaSole: '',
                postaSole: ''
            });
        }
        setTouched({
            ...currentTouched,
            nazivSole: true,
            naslovSole: true,
            postnaStevilkaSole: true,
            postaSole: true
        });
    }

    return <fieldset className="scheduler-border">
        <legend className="scheduler-border">Šola</legend>
        <FormGroup>
            <label className="col-md-2 pr-0 mr-0 col-form-label text-md-right text-sm-left" htmlFor="nazivSoleIzberi">Izberite naziv šole</label>
            <div className="col-md-10">

                {/* JE OK text? */}

                <input
                    className="form-control"
                    placeholder="Pričnite vpisovati naziv šole in jo nato izberite na spustnem seznamu."
                    list="sole"
                    id="nazivSoleIzberi"
                    onChange={e => updateValues(e, values, setValues, touched, setTouched)}
                />

                <datalist id="sole">
                    {sole.map((sola, index) =>
                        <option key={index} value={sola.naziv} />
                    )}
                </datalist>
            </div>
        </FormGroup>
        <FormGroup>
            <InputFormControl errors={errors.nazivSole} touched={touched.nazivSole} readOnly id="nazivSole" colSize={'col-md-10'} title="Naziv šole" />
        </FormGroup>
        <FormGroup>
            <InputFormControl errors={errors.naslovSole} touched={touched.naslovSole} readOnly id="naslovSole" colSize={'col-md-10'} title="Naslov" />
        </FormGroup>
        <FormGroup>
            <InputFormControl errors={errors.postnaStevilkaSole} touched={touched.postnaStevilkaSole} readOnly id="postnaStevilkaSole" colSize={'col-md-3'} title="Poštna številka" />
            <InputFormControl errors={errors.postaSole} touched={touched.postaSole} readOnly id="postaSole" colSize={'col-md-6'} title="Pošta" className="col-md-1 pr-0 mr-0 col-form-label text-md-right text-sm-left" />
        </FormGroup>
    </fieldset>
}