import React from 'react';
import { Field, ErrorMessage } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(faPlus, faTrashAlt, faQuestionCircle);

// /obrazec.pdf
export default ({ obrazecPot, children }) => {
    return (
        <fieldset className="scheduler-border">
            <legend className="scheduler-border">
                Izjave o sprejemanju pogojev
        </legend>
            {children}
            <div className="form-group form-row">
                <div className="col-md-1"></div>
                <div className="col-md-11 col-form-label ml-3 ml-md-0">
                    <Field className="form-check-input" type="checkbox" id="soglasjeStarsi" name="soglasjeStarsi" required />
                    <label className="form-check-label" htmlFor="soglasjeStarsi">Mentor ekipe potrjujem, da sem od staršev vseh tekmovalcev pridobil soglasja o fotografiranju in objavi tekmovalčevih imen in priimkov ali fotografij na oglasnih deskah v šoli, poročilih in medijih.</label>
                    <ErrorMessage component="div" name="soglasjeStarsi" className="text-danger small" />
                </div>
            </div>
            {/*<div className="form-group form-row">
                <div className="col-md-1"></div>
                <div className="col-md-11">
                    Prenesite <a href={process.env.PUBLIC_URL + obrazecPot} download>Obrazec o objavi podatkov tekmovalcev</a> in pridobite soglasja staršev.
                 </div>
            </div>*/}
            <hr className="mb-3 mt-1" />
            <div className="form-group form-row">
                <div className="col-md-1"></div>
                <div className="col-md-11 col-form-label ml-3 ml-md-0">
                    <Field className="form-check-input" type="checkbox" id="sprejemPravil" name="sprejemPravil" required />
                    <label className="form-check-label" htmlFor="sprejemPravil">Mentor ekipe potrjujem, da smo seznanjeni in sprejemamo <a href="https://robobum.um.si/pravila/aktualna-pravila" rel="noopener noreferrer" target="_blank">aktualna pravila tekmovanja</a>.</label>
                    <ErrorMessage component="div" name="sprejemPravil" className="text-danger small" />
                </div>
            </div>
            <hr className="mb-3 mt-1" />
            <div className="form-group form-row mb-md-2 mb-0">
                <div className="col-md-1"></div>
                <div className="col-md-11 col-form-label ml-3 ml-md-0">
                    <Field className="form-check-input" type="checkbox" id="pravilnaPrijava" name="pravilnaPrijava" required />
                    <label className="form-check-label" htmlFor="pravilnaPrijava">Prijava je v celoti in pravilno izpolnjena.</label>
                    <sup title="Prosimo, da pred oddajo prijave vse podatke skrbno preverite in potrdite, da je prijava pravilno izpolnjena.">
                        <FontAwesomeIcon
                            className="fas"
                            icon="question-circle"
                            data-toggle="tooltip"
                        />
                    </sup>
                    <small className="form-text text-muted d-sm-block d-md-none">Prosimo, da pred oddajo prijave vse podatke skrbno preverite in potrdite, da je prijava pravilno izpolnjena.</small>
                    <ErrorMessage component="div" name="pravilnaPrijava" className="text-danger small" />
                </div>
            </div>
        </fieldset>
    )
}