
const capitalize = (word, spacer) =>
    word.split(spacer).map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

const capitalizeFirst = word => word.charAt(0).toUpperCase() + word.slice(1);

export default async (values, wordsToCapitalize) => {
    try {

        for (const word of wordsToCapitalize) {
            if (word.plain) {
                values[word.id] = word.capitalizeAll ? capitalize(values[word.id], ' ') : capitalizeFirst(values[word.id]);
            } else {

                values[word.id].forEach(value => {
                    word.values.forEach((property, index, array) => {
                        value[property] = word.capitalizeAll ? capitalize(value[property], ' ') : capitalizeFirst(value[property]);
                    });
                });
            }
        }
    } catch (error) {
        throw new Error(error);
    } finally {
        return values;
    }
}