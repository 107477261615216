
export const setIFrameHeight = () => {
    const w = document.body.clientWidth;
    const h = document.body.clientHeight + 150;
    window.parent.postMessage({ 'task': 'resize_iframe', payload: { w, h } }, '*');
};

export const scrollToTopParent = () => {
    //console.log(window.parent);
    window.parent.postMessage({ 'task': 'scroll_top' }, '*');
}
