import * as Yup from "yup";
import { solaValidation, tekmovalciValidation, mentorValidation, pravilaValidation, organizatorValidation, imeEkipeValidation } from './validationSchema';

export default Yup.object({
    recaptcha: Yup.string().required(),
    ...solaValidation,
    mentorji:
        Yup.array().of(
            Yup.object(mentorValidation)
        ),
    disciplinaIzberi:
        Yup.string()
            .required("Prosimo, izberite disciplino."),
    nivoIzberi:
        Yup.string()
            .required("Prosimo, izberite nivo."),
    ...imeEkipeValidation,
    ...tekmovalciValidation,
    ...organizatorValidation,
    dosezenoStTock:
        Yup.number()
            .typeError('Doseženo št. točk mora biti število.')
            .required('Prosimo, vpišite doseženo število točk.'),
    ...pravilaValidation
});
