import React, { useState } from 'react';
import { Formik, FieldArray, Field } from 'formik';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import ObrazecService from '../../services/obrazec.service';
import CapitalizeService from '../../services/capitalize.service';
import { scrollToTopParent } from '../../services/iframe.service';

import { Input } from '../../components/InputFormControl';
import SelectFormControl from '../../components/SelectFormControl';

import Sola from '../../components/Sola';
import Tekmovalci from '../../components/Tekmovalci';
import Predtekmovanje from '../../components/Predtekmovanje';
import Pogoji from '../../components/Pogoji';
import Infos from '../../components/Infos';
import Captcha from '../../components/Captcha';

import discipline from '../../data/discipline.json';
import nivo from '../../data/nivo.json';
import srednjeSole from '../../data/srednje.json';
import osnovneSole from '../../data/osnovne.json';
import validationSchemaJunior from '../../schemas/validationSchemaJunior';
import {
    NAJVEC_MENTORJEV,
    NAJMANJ_MENTORJEV,
    NAJVEC_TEKMOVALCEV,
    NAJMANJ_TEKMOVALCEV,
    prazenMentor,
    prazenTekmovalec,
    initialValuesObject,
    wordsToCapitalize
} from './roboCupJunior.config';

library.add(faPlus, faTrashAlt, faQuestionCircle);

const sole = [...srednjeSole, ...osnovneSole].sort((a, b) => (a.naziv > b.naziv) ? 1 : -1)

const scrollToTop = () => window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth'
});

export default ({ history }) => {
    // const year = new Date().getFullYear();
    document.title = 'RoboCupJunior Slovenija '; //+ year;
    const [onlineError, setOnlineError] = useState(false);

    const submitData = async (values, actions) => {
        scrollToTop();
        scrollToTopParent();
        try {
            /* */
            CapitalizeService(values, wordsToCapitalize);
            const mentorEmail = values.mentorji[0].mentorEposta;
            const imeEkipe = values.imeEkipe;
            const response = await ObrazecService(values, process.env.REACT_APP_ROBOCUP_JUNIOR_ENDPOINT, mentorEmail, imeEkipe);
            if (response.status === 201) {  // 201 - created
                history.push('/uspesno-poslano/junior', values);
            }
            /* */
        } catch (error) {
            console.warn(error);
            setOnlineError(true);
        } finally {
            //actions.resetForm();
            actions.setSubmitting(false);
        }
    }

    const isTouched = (touched, indeks, lastnostOtrok, lastnostStars = 'mentorji') =>
        touched[lastnostStars] && touched[lastnostStars][indeks] ? touched[lastnostStars][indeks][lastnostOtrok] : false;

    const hasErrors = (errors, indeks, lastnostOtrok, lastnostStars = 'mentorji') =>
        errors[lastnostStars] && errors[lastnostStars][indeks] ? errors[lastnostStars][indeks][lastnostOtrok] : false;


    const addMentorHandler = (arrayHelpers, stMentorjev) => {
        if (stMentorjev < NAJVEC_MENTORJEV) {
            arrayHelpers.push({
                ...prazenMentor
            });
        }
    }

    const removeMentorHandler = (event, arrayHelpers, indeks, stMentorjev) => {
        event.preventDefault();
        if (stMentorjev > NAJMANJ_MENTORJEV) {
            arrayHelpers.remove(indeks);
        } else {
            alert(`Napaka pri brisanju.`);
        }
    }

    const onButtonClick = e => {
        scrollToTop();
        scrollToTopParent();
    }

    return (
        <Formik
            onSubmit={submitData}
            initialValues={initialValuesObject}
            validationSchema={validationSchemaJunior}
            render={({
                values,
                touched,
                setValues,
                setTouched,
                errors,
                isSubmitting,
                handleSubmit,
                isValid,
                setFieldValue,
                submitCount
            }) => (
                    <>
                        <div className="row">
                            <div className="col mt-5 mb-5">
                                <h2>Prijavnica za državno robotsko tekmovanje v kategoriji</h2>
                                <h1>RoboCupJunior Slovenija {/*year*/}</h1>
                            </div>
                        </div>

                        <Infos isSubmitting={isSubmitting} submitCount={submitCount} isValid={isValid} onlineError={onlineError} />

                        <form onSubmit={handleSubmit} className="needs-validation" noValidate>
                            {/*JSON.stringify(touched)*/}

                            {/* ŠOLA */}
                            <Sola
                                sole={sole}
                                errors={errors}
                                touched={touched}
                                values={values}
                                setTouched={setTouched}
                                setValues={setValues}>
                            </Sola>



                            {/* MENTORJI */}
                            <fieldset className="scheduler-border">
                                <legend className="scheduler-border"> Mentorji </legend>
                                <FieldArray
                                    name="mentorji"
                                    render={arrayHelpers => (
                                        <>
                                            {values.mentorji.map((mentor, index) => (
                                                <span key={index}>
                                                    <div className="row">
                                                        <h6 className="col">Mentor {index + 1}</h6>
                                                    </div>

                                                    <hr className="mt-0 mt-md-0 mb-md-3 mb-0" />
                                                    <div className="form-group form-row mb-md-3 mb-0">

                                                        {index > 0 && (
                                                            <div className="col-md-1 col-form-label">
                                                                <button type="button" className="btn btn-outline-dark btn-sm mt-1" data-toggle="tooltip" title="Odstrani mentorja" onClick={(e) => removeMentorHandler(e, arrayHelpers, index, values.mentorji.length)}>
                                                                    <FontAwesomeIcon icon={['far', "trash-alt"]} className="far" />
                                                                </button>
                                                            </div>
                                                        )}

                                                        <label className={`col-md-${index > 0 ? 1 : 2} pr-0 mr-0 col-form-label text-md-right text-sm-left`} htmlFor={`mentorji.${index}.mentorIme`}>Ime</label>
                                                        <div className="col-md-4">
                                                            <Input
                                                                title="Ime"
                                                                touched={isTouched(touched, index, 'mentorIme')}
                                                                errors={hasErrors(errors, index, 'mentorIme')}
                                                                id={`mentorji.${index}.mentorIme`}
                                                                value={mentor.mentorIme}
                                                                maxLength={35}
                                                                data-capitalize
                                                            />
                                                        </div>
                                                        <label className="col-md-1 pr-0 mr-0 col-form-label text-md-right text-sm-left" htmlFor="mentorPriimek">Priimek</label>
                                                        <div className="col-md-5">
                                                            <Input
                                                                title="Priimek"
                                                                touched={isTouched(touched, index, 'mentorPriimek')}
                                                                errors={hasErrors(errors, index, 'mentorPriimek')}
                                                                id={`mentorji.${index}.mentorPriimek`}
                                                                value={mentor.mentorPriimek}
                                                                maxLength={35}
                                                                data-capitalize
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="form-group form-row mb-md-3 mb-0">
                                                        <label className="col-md-2 pr-0 mr-0 col-form-label text-md-right text-sm-left" htmlFor={`mentorji.${index}.mentorEposta`}>E-pošta</label>
                                                        <div className="col-md-4">
                                                            <Input
                                                                title="E-pošta"
                                                                touched={isTouched(touched, index, 'mentorEposta')}
                                                                errors={hasErrors(errors, index, 'mentorEposta')}
                                                                id={`mentorji.${index}.mentorEposta`}
                                                                value={mentor.mentorEposta}
                                                            />
                                                        </div>
                                                        <label className="col-md-1 pr-0 mr-0 col-form-label text-md-right text-sm-left" htmlFor={`mentorji.${index}.mentorTelefon`}>Telefon</label>
                                                        <div className="col-md-5">
                                                            <Input
                                                                title="Telefon"
                                                                touched={isTouched(touched, index, 'mentorTelefon')}
                                                                errors={hasErrors(errors, index, 'mentorTelefon')}
                                                                id={`mentorji.${index}.mentorTelefon`}
                                                                value={mentor.mentorTelefon}
                                                            />
                                                        </div>
                                                    </div>
                                                </span>
                                            ))}
                                            {values.mentorji.length === NAJMANJ_MENTORJEV && <>
                                                <hr className="mb-0 mt-4" />
                                                <div className="form-group form-row mt-3 mb-0">
                                                    <div className="col-2 text-right">
                                                        <button
                                                            type="button"
                                                            className="btn btn-outline-dark btn-sm"
                                                            onClick={() => addMentorHandler(arrayHelpers, values.mentorji.length)}>
                                                            <FontAwesomeIcon icon="plus" />
                                                        </button>
                                                    </div>
                                                    <label className="col-10 col-form-label text-left">Dodaj mentorja</label>
                                                </div> </>
                                            }
                                        </>
                                    )} />
                            </fieldset>

                            {/* TEKMOVANJE */}
                            <fieldset className="scheduler-border">
                                <legend className="scheduler-border">
                                    Tekmovanje
                            </legend>
                                <div className="form-group form-row mt-3 mb-md-2 mb-0">
                                    <label className="col-md-2 pr-0 mr-0 col-form-label text-md-right text-sm-left" htmlFor="disciplinaIzberi">Izberite disciplino</label>
                                    <div className="col-md-4">
                                        <SelectFormControl errors={errors.disciplinaIzberi} touched={touched.disciplinaIzberi} name={'disciplinaIzberi'} items={discipline} />
                                    </div>
                                    <label className="col-md-2 pr-0 mr-0 col-form-label text-md-right text-sm-left" htmlFor="nivoIzberi">Izberite nivo</label>
                                    <div className="col-md-4">
                                        <SelectFormControl errors={errors.nivoIzberi} touched={touched.nivoIzberi} name={'nivoIzberi'} items={nivo} />
                                    </div>
                                </div>
                            </fieldset>

                            {/* EKIPA */}
                            <fieldset className="scheduler-border">
                                <legend className="scheduler-border">Ekipa</legend>

                                <div className="form-group form-row mt-3 mb-0">
                                    <label className="col-md-2 pr-0 mr-0 col-form-label text-md-right text-sm-left" htmlFor="imeEkipe">Ime ekipe
                                    <sup title="Izbrano ime ekipe naj bo primerno za natis na uradna priznanja.">
                                            <FontAwesomeIcon
                                                className="fas"
                                                icon="question-circle"
                                                data-toggle="tooltip"
                                                onKeyUp={e => e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)}
                                            />
                                        </sup>
                                    </label>
                                    <div className="col-md-10">

                                        <Input
                                            title="Ime ekipe (do 20 znakov)"
                                            touched={touched.imeEkipe}
                                            errors={errors.imeEkipe}
                                            id="imeEkipe"
                                            value={values.imeEkipe}
                                            onKeyUp={e => e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)}
                                            maxLength={20}
                                        />
                                        <small className="form-text text-muted d-sm-block d-md-none">Izbrano ime ekipe naj bo primerno za natis na uradna priznanja.</small>
                                    </div>
                                </div>

                                <div className="form-group form-row mt-3 mb-0">
                                    <div className="col-md-2 col-1 pr-0 mr-0 col-form-label text-sm-right">
                                        <Field type="checkbox" id="mesanaEkipa" checked={values.mesanaEkipa} name="mesanaEkipa" />
                                    </div>
                                    <label className="col-md-10 col-11 pr-0 mr-0 col-form-label text-sm-left" htmlFor="mesanaEkipa">
                                        Mešana ekipa
                                        <sup title="Če so v ekipi pridruženi tekmovalci iz druge šole, izpolnite za te tekmovalce ločeno prijavnico in na obeh prijavnicah označite, da je ekipa mešana.">
                                            <FontAwesomeIcon
                                                className="fas"
                                                icon="question-circle"
                                                data-toggle="tooltip"
                                            />
                                        </sup>
                                    </label>
                                    <small className="form-text text-muted d-sm-block d-md-none">Če so v ekipi pridruženi tekmovalci iz druge šole, izpolnite za te tekmovalce ločeno prijavnico in na obeh prijavnicah označite, da je ekipa mešana.</small>
                                </div>

                                <Tekmovalci
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    NAJMANJ_TEKMOVALCEV={NAJMANJ_TEKMOVALCEV}
                                    NAJVEC_TEKMOVALCEV={NAJVEC_TEKMOVALCEV}
                                    prazenTekmovalec={prazenTekmovalec}
                                    info={'Pravila za tekmovanje RoboCupJunior v Sloveniji omejujejo sestavo ekipe na najmanj 2 in največ 5 tekmovalcev.'}
                                ></Tekmovalci>


                            </fieldset>

                            {/* PREDTEKMOVANJE */}
                            <Predtekmovanje values={values} errors={errors} touched={touched} setValues={setValues}>
                                <div className="form-group form-row mb-md-2 mb-0">
                                    <label className="col-md-4 pr-0 mr-0 col-form-label text-md-right text-sm-left" htmlFor="dosezenoStTock">Doseženo število točk
                                        <sup title="Navedite število doseženih točk za končno uvrstitev ekipe na regijskem predtekmovanju.">
                                            <FontAwesomeIcon
                                                className="fas"
                                                icon="question-circle"
                                                data-toggle="tooltip"
                                            />
                                        </sup>
                                    </label>
                                    <div className="col-md-4">
                                        <Input id="dosezenoStTock" title="Doseženo število točk" errors={errors.dosezenoStTock} touched={touched.dosezenoStTock} required />
                                        <small className="form-text text-muted d-sm-block d-md-none">Navedite število doseženih točk za končno uvrstitev ekipe na regijskem predtekmovanju.</small>
                                    </div>
                                </div>
                            </Predtekmovanje>


                            {/* POGOJI */}
                            <Pogoji obrazecPot={'/vzorec.pdf'}></Pogoji>


                            <Captcha
                                setFieldValue={setFieldValue}
                                name={'recaptcha'}
                                errors={errors.recaptcha}
                                submitCount={submitCount}
                            />

                            <div className="row mb-5">
                                <div className="col-12 text-right">
                                    <button className="btn btn-primary" type="submit" onClick={e => onButtonClick(e, errors)} >Pošlji prijavo</button>
                                </div>
                            </div>
                        </form>
                    </>
                )} />
    );
}