import React from 'react';
import { FieldArray } from 'formik';
import { Input } from './InputFormControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(faPlus, faTrashAlt, faQuestionCircle);

export default ({ values, errors, touched, prazenTekmovalec, NAJMANJ_TEKMOVALCEV, NAJVEC_TEKMOVALCEV, info }) => {

    const isTouched = (touched, indeks, lastnostOtrok, lastnostStars = 'mentorji') =>
        touched[lastnostStars] && touched[lastnostStars][indeks] ? touched[lastnostStars][indeks][lastnostOtrok] : false;

    const hasErrors = (errors, indeks, lastnostOtrok, lastnostStars = 'mentorji') =>
        errors[lastnostStars] && errors[lastnostStars][indeks] ? errors[lastnostStars][indeks][lastnostOtrok] : false;

    const addTekmovalecHandler = (arrayHelpers, stTekmovalcev) => {
        if (stTekmovalcev < NAJVEC_TEKMOVALCEV) {
            arrayHelpers.push({
                ...prazenTekmovalec
            });
        }
    }

    const removeTekmovalecHandler = (event, arrayHelpers, indeks, stTekmovalcev) => {
        event.preventDefault();
        if (stTekmovalcev > NAJMANJ_TEKMOVALCEV) {
            arrayHelpers.remove(indeks);
        } else {
            alert(`Napaka pri brisanju.`);
        }
    }

    return (<FieldArray
        name="tekmovalci"
        render={arrayHelpers => (
            <>
                {values.tekmovalci.map((tekmovalec, index) => (
                    <span key={index}>
                        <div className="row">
                            <h6 className="col">Tekmovalec {index + 1}</h6>
                        </div>
                        <hr className="mt-0 mb-md-3 mb-0" />
                        <div className="form-group form-row mt-0 mb-0">

                            {index > NAJMANJ_TEKMOVALCEV - 1 && (
                                <div className="col-md-1 col-form-label">
                                    <button type="button" className="btn btn-outline-dark btn-sm mt-1" data-toggle="tooltip" title="Odstrani tekmovalca" onClick={(e) => removeTekmovalecHandler(e, arrayHelpers, index, values.tekmovalci.length)}>
                                        <FontAwesomeIcon icon={['far', "trash-alt"]} className="far" />
                                    </button>
                                </div>
                            )}

                            <label className={`col-md-${index > NAJMANJ_TEKMOVALCEV - 1 ? 1 : 2} pr-0 mr-0 col-form-label text-md-right text-sm-left`} htmlFor={`tekmovalci.${index}.tekmovalecIme`}>Ime</label>
                            <div className="col-md-3">
                                <Input
                                    title="Ime"
                                    touched={isTouched(touched, index, 'tekmovalecIme', 'tekmovalci')}
                                    errors={hasErrors(errors, index, 'tekmovalecIme', 'tekmovalci')}
                                    id={`tekmovalci.${index}.tekmovalecIme`}
                                    value={tekmovalec.tekmovalecIme}
                                    data-capitalize
                                    maxLength={35}
                                />
                            </div>

                            <label className="col-md-1 pr-0 mr-0 col-form-label text-lg-right text-md-left" htmlFor={`tekmovalci.${index}.tekmovalecPriimek`}>Priimek</label>
                            <div className="col-md-3">
                                <Input
                                    title="Priimek"
                                    touched={isTouched(touched, index, 'tekmovalecPriimek', 'tekmovalci')}
                                    errors={hasErrors(errors, index, 'tekmovalecPriimek', 'tekmovalci')}
                                    id={`tekmovalci.${index}.tekmovalecPriimek`}
                                    value={tekmovalec.tekmovalecPriimek}
                                    data-capitalize
                                    maxLength={35}
                                />
                            </div>

                            <label className="col-md-1 pr-0 mr-0 col-form-label text-lg-right text-md-left" htmlFor={`tekmovalci.${index}.tekmovalecRazred`}>Razred</label>
                            <div className="col-md-2">
                                <Input
                                    title="Razred"
                                    touched={isTouched(touched, index, 'tekmovalecRazred', 'tekmovalci')}
                                    errors={hasErrors(errors, index, 'tekmovalecRazred', 'tekmovalci')}
                                    id={`tekmovalci.${index}.tekmovalecRazred`}
                                    value={tekmovalec.tekmovalecRazred}
                                    min={1}
                                    maxLength={4}
                                />
                            </div>
                        </div>
                    </span>))}

                {values.tekmovalci.length < NAJVEC_TEKMOVALCEV && <>
                    <hr className="mb-0 mt-4" />
                    <div className="form-group form-row mt-3 mb-0">
                        <div className="col-2 text-right">
                            <button
                                type="button"
                                className="btn btn-outline-dark btn-sm"
                                onClick={() => addTekmovalecHandler(arrayHelpers, values.tekmovalci.length)}>
                                <FontAwesomeIcon icon="plus" />
                            </button>
                        </div>
                        {info && <>
                            <label className="col-10 col-form-label text-left">Dodaj Tekmovalca
                                <sup title={info}>
                                    <FontAwesomeIcon
                                        className="fas"
                                        icon="question-circle"
                                        data-toggle="tooltip"
                                    />
                                </sup>
                            </label>
                            <small className="form-text text-muted d-sm-block d-md-none">{info}</small>
                        </>
                        }
                    </div> </>
                }
            </>
        )} />
    )
}